// Default State
const state = {
    alerts: [],
}

// Module Getters
const getters = {
    allAlerts: state => {
    return state.alerts
}
}

// Module Actions
const actions = {
    addAlert( { commit }, payload ) {
        commit('addAlert', payload)
    },

    dismissAlert( { commit }, ident ) {
        commit('dismissAlert', ident)
    }
}

// Module Mutations
const mutations = {
    // Add A New Alert to the Global Store.
    addAlert ( state, payload ) {
        payload.ident = Math.random().toString(36).substr(2, 16)
        state.alerts.push(payload)
        if( payload.alertImportant == false ) {
            // setTimeout(() => {
            //     this.commit('dismissAlert', payload.ident)
            // }, 5000);
        }
    },
    // Dismiss a Alert and Remove it from the Array.
    dismissAlert ( state, ident ) {
        let index = state.alerts.map(x => x.ident).indexOf( ident );
        state.alerts.splice(index, 1);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
