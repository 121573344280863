<template>
    <div>
        <transition-group 
            name="alert" 
            tag="div"
            enter-active-class="animated fadeInDown"
            leave-to-class="animated fadeOutUp"
        > 
            <div :class="['alert', alert.alertClass, '']" role="alert" v-for="( alert, index ) in alerts" v-bind:key="index + 0">
                <div class="alert-inner-message">
                    <span class="alert-message" v-html="alert.alertMessage"></span>
                </div>

                <div class="alert-inner-close" v-if="alert.alertImportant">
                    <a class="close" v-on:click.prevent="dismiss( alert.ident )" aria-label="Close">
                        OK
                    </a>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

    export default {
        computed: {
            ...mapGetters({
                alerts: 'allAlerts'
            })
        },
        data: () => {
            return {
            }
        },
        mounted() {
        },

        methods: {
            // Map the Methods from the State Module
            ...mapActions(['addAlert', 'dismissAlert']),

            // Method to Dismiss the Alert.
            // calls the Store Mapped Method
            dismiss: function( ident ) {
                this.dismissAlert( ident )
            }

        }
    }
</script>
