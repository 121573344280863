import "@babel/polyfill";

window.Vue = require('vue');

import store from './store';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import alert from './components/alerts/AlertComponent.vue';
Vue.component('alert', alert);

import alertBridge from './components/bridge/AlertBridgeComponent.vue';
Vue.component('alert-bridge', alertBridge);

var elementExists = document.getElementById("tvca-portal");

if (elementExists) {
	const app = new Vue({
	   el: '#tvca-portal',
	   store
	});
}



