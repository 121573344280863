<template>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        props:['session_alerts', 'session_status'],
        data: () => {
            return {
            }
        },
        mounted() {
            let sessionAlerts = JSON.parse(this.session_alerts);
            let sessionStatus = JSON.parse(this.session_status);

            if( sessionAlerts !== null && sessionAlerts.length > 0 ) {
                for( let i=0; i < sessionAlerts.length; i++) {
                    this.addAlert({
                        alertClass:     'alert-' + sessionAlerts[i].level,
                        alertMessage:   sessionAlerts[i].message,
                        alertImportant: (sessionAlerts[i].important === 'true')
                    });
                }
            }

            if( sessionStatus !== null && sessionStatus!='null') {
                this.addAlert({
                    alertClass:     'alert-success',
                    alertMessage:   sessionStatus,
                    alertImportant: false
                });
            }

        },
        methods: {
            // Map the Methods from the State Module
            ...mapActions(['addAlert'])
        }
    }
</script>
